<template>
  <el-card shadow="never">
    <template #header>
      <i class="el-icon-star-off"></i>&nbsp;
      <span>{{ pageID === "" ? "添加活动页" : "活动页编辑（" + config.title + "）" }}</span>
    </template>
    <div v-if="!next" class="content">
      <el-form :model="config" :rules="rules" label-width="120px" class="pdtform" ref="dataform">
        <el-form-item label="活动页标题" prop="title">
          <el-input class="input-size" v-model="config.title" maxlength="30" minlength="4" autocomplete="off"
            placeholder="请输入活动页标题" />
        </el-form-item>
        <el-form-item label="内容区域">
          <el-table class="pdt-list" :data="blocks" width="100%" v-loading="loading" element-loading-text="拼命加载中..."
            element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)" size="small">
            <el-table-column prop="title" label="标题" show-overflow-tooltip />
            <el-table-column label="区域类型" width="140">
              <template #default="scope">
                <template v-for="(obj, i) in areatype" :key="i">
                  {{ scope.row.type == obj.value ? obj.label : "" }}
                </template>
              </template>
            </el-table-column>
            <el-table-column prop="row" label="行数" width="80" />
            <el-table-column prop="column" label="列数" width="80" />
            <el-table-column prop="column" label="显示价格" width="100">
              <template #default="scope">
                <span v-if="scope.row.type == 'goods'">
                  <i v-if="scope.row.showprice * 1 == 1" style="font-size: 16px; color: #409eff"
                    class="el-icon-check"></i>
                  <i v-else style="font-size: 16px; color: red" class="el-icon-close"></i>
                </span>
                <label v-else>--</label>
              </template>
            </el-table-column>
            <el-table-column label="状态" width="80">
              <template #default="scope">
                <label v-if="scope.row.status * 1 == 1" style="color: green">已启用</label>
                <label v-else-if="scope.row.status * 1 == 0" style="color: red">已停用</label>
              </template>
            </el-table-column>
            <el-table-column label="操作" width="300" class-name="cate_control">
              <template #header>
                <div class="tab-header-contorl">
                  操作
                  <el-button type="success" size="small" @click="addArea()">新增区域</el-button>
                </div>
              </template>
              <template #default="scope">
                <el-button type="text" @click.stop="updateStatus(scope.$index)"
                  :class="scope.row.status * 1 == 1 ? 'delete' : ''">{{ scope.row.status * 1 == 0 ? "启用" : "停用"
                  }}</el-button>
                <el-button type="text" @click="addArea(scope.$index)">编辑</el-button>
                <el-button type="text" @click="moveUp(scope.$index)">上移</el-button>
                <el-button type="text" @click="moveDown(scope.$index)">下移</el-button>
                <el-button type="text" @click="delArea(scope.$index)" class="delete">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="nextStep()">下一步</el-button>
          <el-button @click="goBack()">返 回</el-button>
        </el-form-item>
      </el-form>

      <el-dialog title="内容区域编辑器" v-model="editarea" width="40%" @close="closeAreaEdit">
        <el-form :model="curarea" label-width="100px" class="dataform" :rules="rules.blocks" ref="dataform">
          <el-form-item label="标题" prop="title">
            <el-input v-model="curarea.title" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="区域类型">
            <el-select v-model="curarea.type" @change="changeAreaType">
              <el-option v-for="item in areatype" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item v-if="curarea.type == 'coupon' || curarea.type == 'goods'" label="行数">
            <el-input-number v-model="curarea.row" :min="1" controls-position="right"
              style="width: 120px; margin-right: 10px" />
            <el-tag type="danger">将区域划分为 N 行</el-tag>
          </el-form-item>
          <el-form-item v-if="curarea.type == 'coupon' || curarea.type == 'goods'" label="列数">
            <el-input-number v-model="curarea.column" :min="1" :max="3" controls-position="right"
              style="width: 120px; margin-right: 10px" />
            <el-tag type="danger">将每行划分为 M 列，最多划分为 3 列</el-tag>
          </el-form-item>
          <el-form-item v-if="curarea.type == 'menus'" label="数量">
            <el-input-number v-model="curarea.column" :min="1" :max="6" controls-position="right"
              style="width: 120px; margin-right: 10px" />
            <el-tag type="danger">导航按钮数量，最多 6 个</el-tag>
          </el-form-item>
          <el-form-item v-if="curarea.type == 'goods'" label="显示价格">
            <el-switch v-model="curarea.showprice" active-value="1" inactive-value="0" />
            <el-tag type="danger" style="margin-left: 10px">是否显示商品的最长租期的最低日租金价格</el-tag>
          </el-form-item>
          <el-form-item v-if="curarea.type == 'goodslist'" label="展示模式">
            <el-radio-group v-model="curarea.mode" @change="changeListMode">
              <el-radio :label="1">正常模式</el-radio>
              <el-radio :label="2">榜单模式</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item v-if="curarea.type == 'goodslist'" label="每行商品个数">
            <el-input-number v-model="curarea.column" :min="1" :max="2" controls-position="right"
              style="width: 120px; margin-right: 10px" :disabled="curarea.mode == 2" />
            <el-tag type="danger">最多支持 2 个</el-tag>
          </el-form-item>
        </el-form>

        <template #footer>
          <span class="dialog-footer">
            <el-button @click="editarea = false">取 消</el-button>
            <el-button type="primary" @click="saveArea()">确 定</el-button>
          </span>
        </template>
      </el-dialog>
    </div>

    <div v-else class="content">
      <div class="content-header">
        <el-tabs v-model="areaname" type="border-card" @tab-click="handleClick">
          <template v-for="(item, index) in blocks">
            <el-tab-pane v-if="item.status * 1 == 1" :key="item.type + '_' + index" :label="item.title"
              :name="item.type + '_' + index">
              <div class="pane-content">
                <div v-if="item.type != 'goodslist' && item.type != 'showcase'">
                  <div class="prevview">
                    <img v-if="
                      (item.type == 'image' || item.type == 'venue') &&
                      item.bgimage
                    " :src="item.bgimage" class="bgimage" />
                    <div v-else-if="item.type != 'image' && item.type != 'venue'" :class="'items items_' + item.column"
                      :style="'background-image: url(\'' + item.bgimage + '\')'">
                      <template v-for="(obj, i) in item.items" :key="i">
                        <div :class="obj.img ? 'item' : 'item el-icon-plus'" :style="'height:' +
                          item.lineheight +
                          'px; background-image: url(\'' +
                          (obj.img ? obj.img : '') +
                          '\');'
                          " @click="itemEditor(item.type, i)"></div>
                      </template>
                    </div>
                  </div>
                  <el-tag v-if="item.type != 'image' && item.type != 'venue'" size="small" type="danger"
                    style="margin-left: 30px; margin-bottom: 30px">点击 “+” 编辑各项内容</el-tag>
                  <el-tag v-if="item.type == 'venue'" size="small" type="danger"
                    style="margin-left: 30px; margin-bottom: 30px">区域高度：{{
                      item.lineheight > 0 ? item.lineheight + "px" : "自动高度"
                    }}</el-tag>
                </div>
                <div class="settings">
                  <el-form v-if="item.type != 'goodslist' && item.type != 'showcase'" label-width="120px"
                    class="dataform">
                    <el-form-item label="背景图">
                      <el-upload class="avatar-uploader" :action="uploadAction" :on-success="imageUploadSuccess"
                        :on-change="imageUploadChange" :on-error="imgUploadError" :show-file-list="false"
                        :auto-upload="true">
                        <img v-if="item.bgimage" :src="item.bgimage" class="avatar" preview-src-list="[item.bgimage]"
                          hide-on-click-modal="{{true}}" style="max-height: 200px; width: auto" />
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                      </el-upload>
                      <el-tag size="small" type="danger">背景图最佳尺寸：750px × 自定义高度</el-tag>
                    </el-form-item>

                    <el-form-item v-if="item.type == 'image'" label="链接">
                      <el-select v-model="item.linktype" @change="changeLinkType">
                        <el-option v-for="item in linktype" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                      </el-select>
                      <el-button v-if="item.linktype == 'goodslist'" type="success" @click="selGoods"
                        style="margin-left: 10px">商品管理</el-button>
                      <div v-if="
                        item.linktype &&
                        item.linktype != 'notlink' &&
                        item.linktype != 'goodslist' &&
                        item.linktype != 'link'
                      " class="tab-header-contorl">
                        <el-input v-model="item.linkname" autocomplete="off" readonly="true"
                          class="readinput"></el-input>
                        <el-button type="success" @click="selectLink" class="sel-btn">选择</el-button>
                      </div>
                      <div v-else-if="item.linktype == 'link'" class="tab-header-contorl">
                        <el-input v-model="item.link" autocomplete="off" class="readinput"
                          placeholder="请输入链接地址"></el-input>
                      </div>
                    </el-form-item>

                    <el-form-item v-if="item.type != 'image'" label="行高">
                      <el-input-number v-model="item.lineheight" :min="1" controls-position="right"
                        style="width: 120px; margin-right: 10px" />
                      <el-tag size="small" type="danger">每行内容的高度(px)，每行宽度为702px</el-tag>
                    </el-form-item>

                    <el-form-item v-if="item.type == 'goods'" label="显示价格">
                      <el-switch v-model="item.showprice" active-value="1" inactive-value="0" />
                      <el-tag type="danger" style="margin-left: 10px">是否显示商品的最长租期的最低日租金价格</el-tag>
                    </el-form-item>

                    <el-form-item v-if="item.type == 'venue'">
                      <el-button type="success" @click="contentManagement('venue', blockindex)">内容管理</el-button>
                    </el-form-item>
                  </el-form>
                  <div v-else-if="item.type == 'goodslist'">
                    <el-row>
                      <el-col>
                        <el-button type="success" class="f-right" @click="addGoodsMenu('')">新增集合导航</el-button>
                      </el-col>
                    </el-row>
                    <el-table class="pdt-list" :data="item.items" width="100%" style="margin-top: 20px">
                      <el-table-column prop="maintitle" label="主标题" width="220" />
                      <el-table-column label="显示" width="80">
                        <template #default="scope">
                          <label v-if="scope.row.mainshow * 1 == 1" style="color: green">是</label>
                          <label v-else-if="scope.row.mainshow * 1 == 0" style="color: red">否</label>
                        </template>
                      </el-table-column>
                      <el-table-column prop="subtitle" label="副标题" width="220" />
                      <el-table-column label="显示" width="80">
                        <template #default="scope">
                          <label v-if="scope.row.subshow * 1 == 1" style="color: green">是</label>
                          <label v-else-if="scope.row.subshow * 1 == 0" style="color: red">否</label>
                        </template>
                      </el-table-column>
                      <el-table-column label="主题图片" width="100">
                        <template #default="scope">
                          <el-image v-if="scope.row.thumb" class="ad-thumb" :src="scope.row.thumb" />
                          <span v-else>--</span>
                        </template>
                      </el-table-column>
                      <el-table-column label="操作" class-name="cate_control">
                        <template #default="scope">
                          <el-button type="text" @click="selGoods(scope.$index)">商品管理</el-button>
                          <el-button v-if="scope.row.status * 1 == 1" type="text"
                            @click="updateGoodsMenuStatus(scope.$index)" class="delete">停用</el-button>
                          <el-button v-else type="text" @click="updateGoodsMenuStatus(scope.$index)">启用</el-button>
                          <el-button type="text" @click="addGoodsMenu(scope.$index)">编辑</el-button>
                          <el-button type="text" @click="moveUp(scope.$index)">上移</el-button>
                          <el-button type="text" @click="moveDown(scope.$index)">下移</el-button>
                          <el-button type="text" @click="deleteGoodsMenu(scope.$index)" class="delete">删除</el-button>
                        </template>
                      </el-table-column>
                    </el-table>
                  </div>
                  <div v-else-if="item.type == 'showcase'">
                    <el-row>
                      <el-col>
                        <el-button type="success" class="f-right" @click="addShowcase">新增橱窗</el-button>
                      </el-col>
                    </el-row>
                    <el-table class="pdt-list" :data="item.items" width="100%" style="margin-top: 20px">
                      <el-table-column prop="title" label="标题" width="240" />
                      <el-table-column label="背景图片">
                        <template #default="scope">
                          <el-image v-if="scope.row.bgimage" class="ad-thumb showcase" :src="scope.row.bgimage" />
                          <span v-else>--</span>
                        </template>
                      </el-table-column>
                      <el-table-column label="标题颜色" width="80">
                        <template #default="scope">
                          <label class="color-block" :style="'background-color: ' + scope.row.titlecolor"></label>
                        </template>
                      </el-table-column>
                      <el-table-column label="选中颜色" width="80">
                        <template #default="scope">
                          <label class="color-block" :style="'background-color: ' + scope.row.titleactivecolor
                            "></label>
                        </template>
                      </el-table-column>
                      <el-table-column label="商品标题颜色" width="110">
                        <template #default="scope">
                          <label class="color-block" :style="'background-color: ' + scope.row.goodscolor"></label>
                        </template>
                      </el-table-column>
                      <el-table-column label="价格颜色" width="80">
                        <template #default="scope">
                          <label class="color-block" :style="'background-color: ' + scope.row.pricecolor"></label>
                        </template>
                      </el-table-column>
                      <el-table-column label="操作" class-name="cate_control" width="400">
                        <template #default="scope">
                          <el-button type="text" @click="contentManagement('ad', scope.$index)">广告</el-button>
                          <el-button type="text" @click="contentManagement('menus', scope.$index)">菜单管理</el-button>
                          <el-button v-if="scope.row.status * 1 == 1" type="text"
                            @click="updateGoodsMenuStatus(scope.$index)" class="delete">停用</el-button>
                          <el-button v-else type="text" @click="updateGoodsMenuStatus(scope.$index)">启用</el-button>
                          <el-button type="text" @click="addShowcase(scope.$index)">编辑</el-button>
                          <el-button type="text" @click="moveUp(scope.$index)">上移</el-button>
                          <el-button type="text" @click="moveDown(scope.$index)">下移</el-button>
                          <el-button type="text" @click="deleteGoodsMenu(scope.$index)" class="delete">删除</el-button>
                        </template>
                      </el-table-column>
                    </el-table>
                  </div>
                </div>
              </div>
            </el-tab-pane>
          </template>
        </el-tabs>
      </div>
      <div style="margin-top:20px">
        选择类型：<el-select v-model="type" placeholder="请选择类型" style="width: 240px" clearable>
          <el-option label="微信" value="1" />
          <el-option label="支付宝" value="2" />
        </el-select>
      </div>
      <div style="margin-top: 20px">
        <el-button type="primary" @click="next = false">上一步</el-button>
        <el-button type="success" @click="
          canpublish = true;
        saveConfig();
        ">保存设置</el-button>
        <el-button type="warning" plain @click="onPrevview">预览</el-button>
        <el-button @click="goBackList()">返 回</el-button>
      </div>

      <el-dialog title="内容项编辑器" v-model="edititem" width="40%" @close="closeItemEdit">
        <el-form :model="curitem" label-width="100px" class="dataform" :rules="rules.item" ref="dataform">
          <el-form-item label="标题" prop="title">
            <el-input v-model="curitem.title" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="图片">
            <el-upload class="avatar-uploader" :action="uploadAction" :on-success="imageUploadSuccess"
              :on-change="imageUploadChange" :on-error="imgUploadError" :show-file-list="false" :auto-upload="true">
              <img v-if="curitem.img" :src="curitem.img" class="avatar" preview-src-list="[curitem.img]"
                hide-on-click-modal="{{true}}" style="
                  max-height: 200px;
                  max-width: 300px;
                  width: auto;
                  height: auto;
                " />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
            <el-tag size="small" type="danger">图片最佳尺寸：{{ Math.round(702 / blocks[blockindex].column) }}px
              × {{ blocks[blockindex].lineheight }}px</el-tag>
          </el-form-item>
          <el-form-item label="链接">
            <div class="tab-header-contorl">
              <el-input v-if="
                blocks[blockindex].type == 'coupon' ||
                blocks[blockindex].type == 'goods'
              " v-model="curitem.linkname" autocomplete="off" readonly="true" class="readinput"
                style="margin-top: 0"></el-input>
              <el-button v-if="blocks[blockindex].type == 'coupon'" type="success" @click="selectCoupon" class="sel-btn"
                style="margin-top: 0">选择优惠券</el-button>
              <el-button v-if="blocks[blockindex].type == 'goods'" type="success" @click="selectGoods" class="sel-btn"
                style="margin-top: 0">选择商品</el-button>
              <el-select v-if="blocks[blockindex].type == 'menus'" v-model="curitem.target" @change="changeAreaType">
                <template v-for="(item, i) in blocks" :key="i">
                  <el-option v-if="i != blockindex && item.status * 1 == 1" :label="item.title"
                    :value="item.id"></el-option>
                </template>
              </el-select>
            </div>
          </el-form-item>
        </el-form>
        <template #footer>
          <span class="dialog-footer">
            <el-button type="danger" @click="resetItem()">重置当前项</el-button>
            <el-button @click="edititem = false">取 消</el-button>
            <el-button type="primary" @click="saveItem()">确 定</el-button>
          </span>
        </template>
      </el-dialog>
    </div>
  </el-card>

  <LinkBlock :linktype="curlinktype" :showlink="showlink" :curgoodsid="curgoodsid" :curlink="curlink"
    v-on:close="onClose" v-on:submit="setLink"></LinkBlock>

  <el-dialog title="活动页预览" v-model="showprevview" width="40%" @close="closePrevview">
    <div class="pane-content" style="height: 600px; overflow: auto">
      <div class="prevview" style="margin: 0 auto">
        <template v-for="(block, i) in blocks" :key="i">
          <div v-if="block.status * 1 == 1" :id="block.id" class="act-block">
            <img v-if="block.type == 'image' && block.bgimage" :src="block.bgimage" class="bgimage" />
            <div v-else-if="block.type == 'goodslist'" :class="'goodslist items items_' + block.column">
              <div class="goods-menu">
                <template v-for="(obj, i) in block.items" :key="i">
                  <span :class="'btn' + (i == previewgoodsmenuindex ? ' active' : '')
                    ">
                    <el-image class="icon" v-if="obj.thumb" :src="obj.thumb" />
                    <span class="main-title" v-if="obj.mainshow * 1 == 1">{{
                      obj.maintitle
                      }}</span>
                    <span class="sub-title" v-if="obj.subshow * 1 == 1">{{
                      obj.subtitle
                      }}</span>
                  </span>
                </template>
              </div>

              <div :class="'list list-0' + block.column">
                <template v-if="block.column == 2">
                  <ul class="pdtlist">
                    <template v-for="(obj, i) in previewgoodslist" :key="i">
                      <li v-if="i % 0 == 0" class="pdt-item">
                        <img class="thumb" :src="obj.PdtThumb" />
                        <div class="info">
                          <span class="pdt-name">{{ obj.PdtName }}</span>
                          <span class="pdt-price">
                            <label class="price">x.xx</label>
                            <label>xx月起租</label>
                          </span>
                        </div>
                      </li>
                    </template>
                  </ul>
                  <ul class="pdtlist">
                    <template v-for="(obj, i) in previewgoodslist" :key="i">
                      <li v-if="i % 0 == 1" class="pdt-item">
                        <img class="thumb" :src="obj.PdtThumb" />
                        <div class="info">
                          <span class="pdt-name">{{ obj.PdtName }}</span>
                          <span class="pdt-price">
                            <label class="price">x.xx</label>
                            <label>xx月起租</label>
                          </span>
                        </div>
                      </li>
                    </template>
                  </ul>
                </template>
                <template v-else>
                  <ul class="pdtlist">
                    <template v-for="(obj, i) in previewgoodslist" :key="i">
                      <li class="pdt-item">
                        <img class="thumb" :src="obj.PdtThumb" />
                        <div class="info">
                          <span class="pdt-name">{{ obj.PdtName }}</span>
                          <span v-if="obj.hotrent > 0" class="hotrent">热租指数：{{ obj.hotrent }}</span>
                          <span>xx月起租</span>
                          <span class="pdt-price">x.xx</span>
                        </div>
                      </li>
                    </template>
                  </ul>
                </template>
              </div>
            </div>

            <div v-else :class="'items items_' + block.column" :style="(block.type == 'venue' ? 'padding: 0; ' : '') +
              'border-radius: 0; background-image: url(\'' +
              (block.bgimage ? block.bgimage : '') +
              '\');' +
              (block.lineheight > 0
                ? 'height: ' + block.lineheight + 'px;'
                : '')
              ">
              <template v-if="block.type == 'venue'">
                <template v-for="(obj, i) in block.items" :key="i">
                  <template v-if="obj.items.length > 0">
                    <div :style="'display: inline-flex; width: 100%; height:' +
                      obj.height +
                      'px;'
                      ">
                      <template v-for="(subobj, j) in obj.items" :key="j">
                        <div :style="'width: ' +
                          100 / obj.items.length +
                          '%; height:' +
                          subobj.height +
                          'px; background-image: url(\'' +
                          (subobj.src ? subobj.src : '') +
                          '\''
                          "></div>
                      </template>
                    </div>
                  </template>
                  <div v-else :style="'width: 100%; height:' +
                    obj.height +
                    'px; background-image: url(\'' +
                    (obj.img ? obj.img : '') +
                    '\');'
                    "></div>
                </template>
              </template>

              <template v-else>
                <template v-for="(obj, i) in block.items" :key="i">
                  <div class="item" :style="'border: 0; height:' +
                    block.lineheight +
                    'px; background-image: url(\'' +
                    (obj.img ? obj.img : '') +
                    '\');'
                    "></div>
                </template>
              </template>
            </div>
          </div>
        </template>
      </div>
    </div>
  </el-dialog>

  <el-dialog title="集合导航编辑" v-model="showgoodsmenu" width="40%" @close="closeGoodsMenu">
    <el-form :model="curgoodsmenu" :rules="rules.goodsmenu" ref="dataform" label-width="100px" class="dataform">
      <el-form-item class="block-form-item" label="主标题" prop="maintitle">
        <el-input v-model="curgoodsmenu.maintitle" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item class="block-form-item" label="是否显示">
        <el-radio-group v-model="curgoodsmenu.mainshow">
          <el-radio-button :label="1">是</el-radio-button>
          <el-radio-button :label="0">否</el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item class="block-form-item" label="副标题" prop="maintitle">
        <el-input v-model="curgoodsmenu.subtitle" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item class="block-form-item" label="是否显示">
        <el-radio-group v-model="curgoodsmenu.subshow">
          <el-radio-button :label="1">是</el-radio-button>
          <el-radio-button :label="0">否</el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="主题图片">
        <el-upload class="avatar-uploader" :action="uploadAction" :on-success="imageUploadSuccess"
          :on-change="imageUploadChange" :on-error="imgUploadError" :show-file-list="false" :auto-upload="true">
          <img v-if="curgoodsmenu.thumb" :src="curgoodsmenu.thumb" class="avatar"
            preview-src-list="[curgoodsmenu.thumb]" hide-on-click-modal="{{true}}"
            style="max-height: 70px; max-width: 70px; width: auto; height: auto" />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
        <el-tag size="small" type="danger">图片最佳尺寸：70px × 70px</el-tag>
      </el-form-item>
    </el-form>

    <template #footer>
      <span class="dialog-footer">
        <el-button @click="closeGoodsMenu">取 消</el-button>
        <el-button type="primary" @click="saveGoodsMenu">保 存</el-button>
      </span>
    </template>
  </el-dialog>

  <el-dialog title="橱窗编辑" v-model="showshowcase" width="40%" @close="closeShowcase">
    <el-form :model="curshowcase" :rules="rules.showcase" ref="dataform" label-width="100px" class="dataform">
      <el-form-item class="block-form-item" label="标题" prop="title">
        <el-input v-model="curshowcase.title" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="背景图片">
        <el-upload class="avatar-uploader" :action="uploadAction" :on-success="imageUploadSuccess"
          :on-change="imageUploadChange" :on-error="imgUploadError" :show-file-list="false" :auto-upload="true">
          <img v-if="curshowcase.bgimage" :src="curshowcase.bgimage" class="avatar"
            preview-src-list="[curshowcase.bgimage]" hide-on-click-modal="{{true}}" style="
              max-height: 300px;
              max-width: 300px;
              width: auto;
              height: auto;
            " />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
        <el-tag size="small" type="danger">图片最佳尺寸：宽度750px，高度自定义</el-tag>
      </el-form-item>
      <el-form-item label="橱窗标题颜色">
        <div style="display: flex; align-items: center">
          <el-color-picker v-model="curshowcase.titlecolor" />
          <span style="margin: 0 12px 0 40px">标题选中颜色</span>
          <el-color-picker v-model="curshowcase.titleactivecolor" />
        </div>
      </el-form-item>
      <el-form-item label="商品标题颜色">
        <div style="display: flex; align-items: center">
          <el-color-picker v-model="curshowcase.goodscolor" />
        </div>
      </el-form-item>
      <el-form-item label="商品价格颜色">
        <div style="display: flex; align-items: center">
          <el-color-picker v-model="curshowcase.pricecolor" />
        </div>
      </el-form-item>
    </el-form>

    <template #footer>
      <span class="dialog-footer">
        <el-button @click="closeShowcase">取 消</el-button>
        <el-button type="primary" @click="saveShowcase">保 存</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { ElMessage, ElMessageBox } from "element-plus";
import pageconst from "@/pageconst";
import LinkBlock from "@/components/LinkBlock";

export default {
  data() {
    return {
      type: "",
      uploadAction: pageconst.upload_pdt_detail_url,
      pageID: "",
      config: {},
      blocks: [],
      rules: {
        title: [
          { required: true, message: "请输入活动页标题", trigger: "blur" },
        ],
        blocks: {
          title: [
            { required: true, message: "请输入区域标题", trigger: "blur" },
          ],
        },
        item: {
          title: [
            { required: true, message: "请输入内容标题", trigger: "blur" },
          ],
        },
        goodsmenu: {
          maintitle: [
            { required: true, message: "请输主标题", trigger: "blur" },
          ],
          subtitle: [
            { required: true, message: "请输副标题", trigger: "blur" },
          ],
        },
        showcase: {
          title: [{ required: true, message: "请输橱窗标题", trigger: "blur" }],
        },
      },
      init: true,
      next: false,
      editarea: false,
      canpublish: false,
      areaindex: "",
      curarea: {},
      orgarea: "",
      areatype: [
        {
          value: "image",
          label: "图片区域",
        },
        {
          value: "coupon",
          label: "优惠券区域",
        },
        {
          value: "goods",
          label: "商品区域",
        },
        {
          value: "menus",
          label: "导航区域",
        },
        {
          value: "venue",
          label: "主场活动",
        },
        {
          value: "goodslist",
          label: "下拉集合页",
        },
        {
          value: "showcase",
          label: "橱窗位",
        },
      ],
      areaname: "",
      blockindex: 0,
      linktype: [
        {
          value: "notlink",
          label: "不跳转",
        },
        {
          value: "page",
          label: "页面跳转",
        },
        {
          value: "goods",
          label: "商品",
        },
        {
          value: "goodslist",
          label: "商品集合页",
        },
        {
          value: "link",
          label: "链接",
        },
      ],
      curlinktype: "notlink",
      showlink: false,
      curgoodsid: "",
      curlink: "",
      edititem: false,
      itemindex: "",
      curitem: {},
      showprevview: false,
      showgoodsmenu: false,
      curgoodsmenu: {},
      orggoodsmenu: "",
      curgoodsmenuindex: "",
      previewgoodslist: [],
      previewgoodsmenuindex: 0,
      showshowcase: false,
      curshowcase: {},
      orgshowcase: "",
      curshowcaseindex: "",
    };
  },
  components: {
    LinkBlock,
  },
  methods: {
    goBack() {
      history.go(-1);
    },

    goBackList() {
      this.$router.push({
        path: "/page/activity",
      });
    },

    nextStep() {
      console.log(this.$route.query.next)
      let that = this;
      this.$refs["dataform"].validate((res) => {
        if (res) {
          if (that.blocks.length <= 0) {
            ElMessage.error("请先添加内容区域！");
            return false;
          }

          that.config.blocks = that.blocks;
          that.saveConfig(function () {
            that.next = true;
            that.areaname = that.blocks[0].type + "_0";

            if (that.init) {
              that.publishPage();
            }

            that.$router.push({
              path: "/page/actdetail",
              query: { pagename: that.pageID, next: 1 },
            });
          });
        }
      });
    },

    publishPage() {
      this.axios
        .get(pageconst.pagemanage + "?entry=publish&type=" + this.type + "&pagename=" + this.pageID)
        .then((response) => {
          if (response.data == "SUCCESS") {
            ElMessage({
              message: "保存成功",
              type: "success",
            });
          } else {
            ElMessage.error({
              showClose: true,
              message: response.data,
            });
            return false;
          }
        });
      this.canpublish = false;
    },

    saveConfig(callback = null) {
      console.log(this.type)
      let next = this.$route.query.next
      if (this.type == '' && next == 1) {
        this.$message.error('请选择类型')
        return
      }
      let that = this;
      this.axios
        .post(
          pageconst.pagemanage + "?entry=saveconfig&pagename=" + that.pageID,
          JSON.stringify(that.config),
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        )
        .then((response) => {
          if (response.data.indexOf("SUCCESS|") !== -1) {
            console.log("保存成功");
            //that.Init();
            if (that.next && that.canpublish) {
              that.publishPage();
            } else if (callback) {
              that.pageID = response.data.split("|")[1];
              callback();
            }
          } else {
            ElMessage.error({
              showClose: true,
              message: response.data,
            });
            return false;
          }
        });
    },

    closeAreaEdit() {
      this.editarea = false;
      if (this.orgarea !== "") {
        this.blocks[this.areaindex] = JSON.parse(this.orgarea);
      }
    },

    // 新增/编辑内容区域
    addArea(index = "") {
      this.areaindex = index;
      if (index !== "") {
        this.curarea = this.blocks[index];
        if (!this.curarea.mode) {
          this.curarea.mode = 1;
        }
        this.orgarea = JSON.stringify(this.curarea);
      } else {
        this.curarea = {};
        this.curarea.status = 0;
        this.curarea.title = "";
        this.curarea.type = "image";
        this.curarea.row = 1;
        this.curarea.column = 1;
        this.curarea.showprice = 0;
        this.curarea.linktype = "notlink";
        this.curarea.lineheight = 200;
        this.curarea.items = [];

        this.orgarea = "";
      }
      this.editarea = true;
    },

    changeAreaType(type) {
      this.curarea.type = type;
      if (type == "image") {
        this.curarea.linktype = "notlink";
        delete this.curarea.lineheight;
        delete this.curarea.items;
      } else if (type == "goodslist") {
        this.curarea.mode = 1;
      } else {
        this.curarea.lineheight = 200;
        this.curarea.items = [];
        delete this.curarea.linktype;
      }
    },

    // 改变下拉集合列表模式
    changeListMode(val) {
      if (val == 2) {
        this.curarea.column = 1;
      }
    },

    saveArea() {
      //console.log(this.areaindex === "", this.areaindex);
      let that = this;
      this.$refs["dataform"].validate((res) => {
        if (res) {
          if (
            that.curarea.type != "image" &&
            that.curarea.type != "venue" &&
            that.curarea.type != "goodslist" &&
            that.curarea.type != "showcase"
          ) {
            let itemcount = that.curarea.row * that.curarea.column;
            that.curarea.items = [];
            for (let i = 0; i < itemcount; i++) {
              that.curarea.items.push({});
            }
          }

          if (that.areaindex !== "") {
            that.curarea.id = that.curarea.type + "_" + that.areaindex;
            that.blocks[that.areaindex] = that.curarea;
            that.orgarea = "";
          } else {
            that.curarea.id = that.curarea.type + "_" + that.blocks.length;
            that.blocks.push(that.curarea);
          }
          that.editarea = false;
        }
      });
    },

    swapArray(arr, index1, index2) {
      arr[index1] = arr.splice(index2, 1, arr[index1])[0];
      return arr;
    },
    moveUp(index) {
      if (index != 0) {
        if (this.blocks[this.blockindex].type == "goodslist") {
          this.blocks[this.blockindex].items = [
            ...this.swapArray(
              this.blocks[this.blockindex].items,
              index,
              index - 1
            ),
          ];
        } else {
          this.blocks = [...this.swapArray(this.blocks, index, index - 1)];
        }
        this.saveConfig();
      }
    },
    moveDown(index) {
      if (index + 1 != this.blocks.length) {
        if (this.blocks[this.blockindex].type == "goodslist") {
          this.blocks[this.blockindex].items = [
            ...this.swapArray(
              this.blocks[this.blockindex].items,
              index,
              index + 1
            ),
          ];
        } else {
          this.blocks = [...this.swapArray(this.blocks, index, index + 1)];
        }
        this.saveConfig();
      }
    },

    delArea(index) {
      if (isNaN(index)) {
        console.log("索引出错");
        return false;
      }

      if (this.blocks[index].status * 1 == 1) {
        ElMessage.error(this.blocks[index].title + " 处于启用状态，无法删除！");
        return false;
      }

      let that = this;
      ElMessageBox.confirm(
        "您确定要删除" + that.blocks[index].title + " 吗？",
        "系统提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          that.blocks.splice(index, 1);
          that.saveConfig();
        })
        .catch(() => {
          console.log("取消操作");
        });
    },

    updateStatus(index) {
      if (isNaN(index)) {
        console.log("索引出错");
        return false;
      }

      let curstatus = this.blocks[index].status * 1,
        that = this;
      if (curstatus == 1) {
        // 询问
        let that = this;
        ElMessageBox.confirm(
          "您确定要停用 " + that.blocks[index].title + " 吗？",
          "系统提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
        )
          .then(() => {
            that.blocks[index].status = 0;
            that.saveConfig();
          })
          .catch((error) => {
            console.log("取消操作", error);
          });
      } else {
        this.blocks[index].status = 1;
        this.saveConfig(function () {
          console.log(that.pageID);
          if (that.init) {
            that.publishPage();
            that.$router.push({
              path: "/page/actdetail",
              query: { pagename: that.pageID },
            });
            that.Init();
          }
        });
      }
    },

    handleClick({ props }) {
      let { name } = props;

      let that = this;
      that.canpublish = false;
      this.saveConfig(function () {
        that.edititem = false;
        that.blockindex = name.split("_")[1];

        that.$router.push({
          path: "/page/actdetail",
          query: { pagename: that.pageID, next: 1, index: that.blockindex },
        });
      });
    },

    imageUploadChange(file) {
      let isSubmit = file.raw.size / 1024 / 1024 < 2;
      console.log("image change ==> ", file);
      if (!isSubmit) {
        if (!this.edititem) {
          if (this.showgoodsmenu) {
            this.$message.error("主题图片大小不能超过 2MB!");
          } else {
            this.$message.error("背景图大小不能超过 2MB!");
          }
        }
        return false;
      }
    },
    imageUploadSuccess(res) {
      console.log(
        "image upload success ==> ",
        res,
        this.edititem,
        this.blockindex
      );
      if (!this.edititem) {
        if (this.showgoodsmenu) {
          // 集合导航主题图片
          this.curgoodsmenu.thumb = res.url;
        } else if (this.showshowcase) {
          // 橱窗背景图片
          this.curshowcase.bgimage = res.url;
        } else {
          // 区域背景图片
          this.blocks[this.blockindex].bgimage = res.url;
        }
      } else {
        this.curitem.img = res.url;
      }
    },
    imgUploadError(err) {
      console.log("image upload error ==>", err);
    },

    changeLinkType(type) {
      console.log(
        "change link type ==> ",
        type,
        this.blocks[this.blockindex].type
      );

      this.curlinktype = type;
      let blocktype = this.blocks[this.blockindex].type,
        curblock = {};

      if (blocktype == "image") curblock = this.blocks[this.blockindex];

      curblock.link = "";

      if (type != "notlink" && type != "goodslist" && type != "link") {
        curblock.linkname = "";
        this.selgoods = "radio";
        this.showlink = true;
      } else if (type == "goodslist") {
        curblock.linkname = "商品集合页";
      } else if (type == "link") {
        curblock.linkname = "自定义链接";
      } else {
        curblock.linkname = "不跳转";
      }

      if (blocktype == "image") this.blocks[this.blockindex] = curblock;
    },
    onClose(isclose) {
      this.showlink = isclose;
    },
    setLink(link, linkname, goodsid = "") {
      if (this.edititem && !isNaN(this.itemindex)) {
        this.curitem.link = link;
        this.curitem.linkname = linkname;

        if (this.blocks[this.blockindex].type == "goods") {
          this.curitem.linktype = "goods";
          this.curitem.goodsid = goodsid;
        } else if (this.blocks[this.blockindex].type == "coupon") {
          this.curitem.linktype = "coupon";
          this.curitem.goodsid = goodsid;
        }

        this.blocks[this.blockindex].items[this.itemindex] = this.curitem;
      } else {
        let blocktype = this.blocks[this.blockindex].type,
          curblock = {};

        if (blocktype == "image") curblock = this.blocks[this.blockindex];

        curblock.link = link;
        curblock.linkname = linkname;
        curblock.goodsid = goodsid;

        if (blocktype == "image") this.blocks[this.blockindex] = curblock;
      }
    },
    selGoods(index = "") {
      let that = this,
        blocktype = this.blocks[this.blockindex].type;

      this.canpublish = false;
      if (blocktype == "image") {
        this.saveConfig();
        this.$router.push({
          path: "/page/goodsmanage",
          query: {
            pagename: that.pageID,
            type: "blocks",
            index: that.blockindex,
          },
        });
      } else if (blocktype == "goodslist" || blocktype == "showcase") {
        this.saveConfig();
        this.$router.push({
          path: "/page/goodsmanage",
          query: {
            pagename: that.pageID,
            type: blocktype,
            parentindex: that.blockindex,
            index: index,
          },
        });
      }
    },

    itemEditor(type, index) {
      this.edititem = true;
      this.itemindex = index;

      this.curitem = this.blocks[this.blockindex].items[index];
      this.orgitem = JSON.stringify(this.curitem);

      if (!this.curitem.title) this.curitem.title = "";
      if (this.blocks[this.blockindex].type != "menus") {
        if (!this.curitem.linktype) this.curitem.linktype = "notlink";
        if (!this.curitem.linkname) this.curitem.linkname = "不跳转";
        if (!this.curitem.link) this.curitem.link = "";
      } else {
        if (!this.curitem.target) this.curitem.target = "";
      }
    },
    closeItemEdit() {
      this.edititem = false;
      if (this.orgitem !== "") {
        this.curitem = JSON.parse(this.orgitem);
      }
    },
    saveItem() {
      console.log("保存项");
      let that = this;
      this.$refs["dataform"].validate((res) => {
        if (res) {
          that.blocks[this.blockindex].items[this.itemindex] = this.curitem;
          this.orgitem = "";
          that.edititem = false;
        }
      });
    },
    resetItem() {
      let that = this;
      ElMessageBox.confirm("您确定要重置当前项吗？", "系统提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          that.curitem.title = "";
          that.curitem.img = "";
          that.curitem.link = "";
          that.curitem.linkname = "";
          that.curitem.goodsid = "";
          that.curitem.linktype = "notlink";

          that.blocks[that.blockindex].items[that.itemindex] = that.curitem;
          that.orgitem = "";
        })
        .catch(() => {
          console.log("取消操作");
        });
    },

    selectGoods() {
      this.curlinktype = "goods";
      this.showlink = true;
      this.curgoodsid = this.curitem.goodsid ? this.curitem.goodsid : "";
    },

    selectCoupon() {
      this.curlinktype = "coupon";
      this.showlink = true;
      this.curgoodsid = this.curitem.goodsid ? this.curitem.goodsid : "";
    },

    onPrevview() {
      this.showprevview = true;
    },
    closePrevview() {
      this.showprevview = false;
    },

    // 编辑集合导航
    addGoodsMenu(index = "") {
      console.log("goodsmenu index => ", index);
      this.curgoodsmenuindex = index;

      console.log(index != "" && !isNaN(index * 1) && index >= 0);
      console.log("items 1 => ", JSON.stringify(this.config.blocks[this.blockindex].items));

      if (index != "" && !isNaN(index * 1) && index >= 0) {
        this.curgoodsmenu = this.config.blocks[this.blockindex].items[index];
      } else {
        this.curgoodsmenu = {};
        this.curgoodsmenu.maintitle = "";
        this.curgoodsmenu.mainshow = 1;
        this.curgoodsmenu.subtitle = "";
        this.curgoodsmenu.subshow = 1;
        this.curgoodsmenu.thumb = "";
        this.curgoodsmenu.status = 0;
      }
      console.log("curgoodsmenu => ", this.curgoodsmenu);
      this.orggoodsmenu = JSON.stringify(this.curgoodsmenu);
      this.showgoodsmenu = true;

      console.log("items 2 => ", JSON.stringify(this.config.blocks[this.blockindex].items));
    },

    closeGoodsMenu() {
      if (this.curgoodsmenuindex != "" && !isNaN(this.curgoodsmenuindex * 1) && this.curgoodsmenuindex >= 0) {
        this.config.blocks[this.blockindex].items[this.curgoodsmenuindex] =
          JSON.parse(this.orggoodsmenu);
        this.curgoodsmenuindex = "";
      }
      this.showgoodsmenu = false;
    },

    saveGoodsMenu() {
      this.$refs["dataform"].validate((valid) => {
        console.log(valid, this.curgoodsmenuindex, this.curgoodsmenuindex == "");
        if (valid) {
          if (this.curgoodsmenuindex == "") {
            // 新增集合导航项
            this.config.blocks[this.blockindex].items.push(this.curgoodsmenu);
          } else {
            // 修改集合导航
            this.config.blocks[this.blockindex].items[this.curgoodsmenuindex] =
              this.curgoodsmenu;
          }
          console.log("items 3 => ", JSON.stringify(this.config.blocks[this.blockindex].items));
          this.saveConfig();
          this.curgoodsmenuindex = "";
          this.showgoodsmenu = false;
        }
      });
    },

    updateGoodsMenuStatus(index) {
      if (isNaN(index) || index < 0) {
        console.log("索引出错");
        return false;
      }

      let curgoodsmenu = this.blocks[this.blockindex].items[index],
        curstatus = curgoodsmenu.status * 1;
      if (curstatus == 1) {
        // 询问
        let that = this,
          title = "";
        if (this.blocks[this.blockindex].type == "goodslist") {
          title = curgoodsmenu.maintitle;
        } else if (this.blocks[this.blockindex].type == "showcase") {
          title = curgoodsmenu.title;
        }
        ElMessageBox.confirm("您确定要停用 " + title + " 吗？", "系统提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            that.blocks[that.blockindex].items[index].status = 0;
            that.saveConfig();
          })
          .catch((error) => {
            console.log("取消操作", error);
          });
      } else {
        this.blocks[this.blockindex].items[index].status = 1;
        this.saveConfig();
      }
    },

    deleteGoodsMenu(index) {
      if (isNaN(index) || index < 0) {
        console.log("索引出错");
        return false;
      }

      let curgoodsmenu = this.blocks[this.blockindex].items[index];
      let that = this,
        title = "";
      if (this.blocks[this.blockindex].type == "goodslist") {
        title = curgoodsmenu.maintitle;
      } else if (this.blocks[this.blockindex].type == "showcase") {
        title = curgoodsmenu.title;
      }

      if (curgoodsmenu.status * 1 == 1) {
        ElMessage.error(title + " 处于启用状态，无法删除！");
        return false;
      }

      ElMessageBox.confirm("您确定要删除" + title + " 吗？", "系统提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          that.blocks[this.blockindex].items.splice(index, 1);
          that.saveConfig();
        })
        .catch(() => {
          console.log("取消操作");
        });
    },

    getGoodsList(pdtids) {
      let that = this;
      this.axios
        .get(
          pageconst.pagemanage +
          "?entry=getgoodslist&goodsids=" +
          JSON.stringify(pdtids),
          {
            headers: { "Content-Type": "application/json" },
          }
        )
        .then(({ data }) => {
          var { list } = data;
          let goodsids =
            that.blocks[that.blockindex].items[that.previewgoodsmenuindex]
              .goodsids;
          console.log("goodsids => ", goodsids);
          for (let i = 0; i < list.length; i++) {
            list[i].hotrent = goodsids[i].hotrent;
          }
          console.log("list =>", list);
          that.previewgoodslist = list;
        });
    },

    /* 橱窗 */
    addShowcase(index = "") {
      this.curshowcaseindex = index;
      if (index != "" && isNaN(index * 1) || index < 0) {
        this.curshowcase = {};
        this.curshowcase.title = "";
        this.curshowcase.bgimage = "";
        this.curshowcase.titlecolor = "#bfbfbf";
        this.curshowcase.titleactivecolor = "#ffac28";
        this.curshowcase.goodscolor = "#000000";
        this.curshowcase.pricecolor = "#ff6666";
        this.orgshowcase = JSON.stringify({});
      } else {
        this.curshowcase = this.config.blocks[this.blockindex].items[index];
        this.orgshowcase = JSON.stringify(this.curshowcase);
      }
      this.showshowcase = true;
    },
    closeShowcase() {
      if (this.curshowcaseindex != "" && !isNaN(this.curshowcaseindex * 1) && this.curshowcaseindex >= 0) {
        this.config.blocks[this.blockindex].items[this.curshowcaseindex] =
          JSON.parse(this.orgshowcase);
        this.curshowcaseindex = "";
      }
      this.showshowcase = false;
    },
    saveShowcase() {
      this.$refs["dataform"].validate((valid) => {
        if (valid) {
          if (isNaN(this.curshowcaseindex * 1)) {
            // 新增集合导航项
            this.config.blocks[this.blockindex].items.push(this.curshowcase);
          } else {
            // 修改集合导航
            this.config.blocks[this.blockindex].items[this.curshowcaseindex] =
              this.curshowcase;
          }
          this.curshowcaseindex = "";
          this.saveConfig();
          this.showshowcase = false;
        }
      });
    },
    contentManagement(type, index) {
      let that = this;
      if (type == "menus" || type == "ad") {
        this.$router.push({
          path: "/page/menumanage",
          query: {
            pagename: that.pageID,
            blockindex: that.blockindex,
            type: type,
            index: index,
          },
        });
      } else {
        this.$router.push({
          path: "/page/contentmanage",
          query: { pagename: that.pageID, type: type, index: index },
        });
      }
    },

    Init() {
      let { pagename, next, index } = this.$route.query;
      pagename = pagename !== "" ? pagename : "compage_";
      next = next != 1 ? false : true;
      index = isNaN(index) ? 0 : index;

      this.pageID = pagename;
      this.next = next;
      this.init = pagename != "compage_" ? false : true;

      console.log(this.pageID, this.init);

      let that = this;
      this.axios
        .get(
          pageconst.pagemanage + "?entry=getconfig&pagename=" + this.pageID,
          {
            headers: { "Content-Type": "application/json" },
          }
        )
        .then(({ data }) => {
          var { config } = data;
          this.config = config ? config : {};
          this.blocks = config.blocks ? config.blocks : [];
          this.isload = true;
          if (this.blocks.length > 0 && this.blocks.length > index) {
            this.blockindex = index;
            this.areaname = this.blocks[index].type + "_" + index;

            if (this.blocks[index].type == "goodslist") {
              let items = this.blocks[index].items,
                pdtids = [];
              for (let i = 0; i < items.length; i++) {
                if (
                  Array.isArray(items[i].goodsids) &&
                  items[i].goodsids.length > 0
                ) {
                  that.previewgoodsmenuindex = i;
                  for (let j = 0; j < items[i].goodsids.length; j++) {
                    if (items[i].goodsids[j].status * 1 == 1) {
                      pdtids.push(items[i].goodsids[j].id);
                    }
                  }
                  break;
                }
              }

              if (pdtids.length > 0) {
                that.getGoodsList(pdtids);
              }
            }
          }
        });
    },
  },
  created: function () {
    this.Init();
  },
};
</script>

<style>
.el-card {
  padding: 0 !important;
}

.el-card__body {
  padding: 10px;
}

.el-card__header {
  padding: 10px !important;
}

.pdt-list {
  border: 1px solid #ebeef5;
  border-bottom: 0;
  border-radius: 4px;
}

.tab-header-contorl {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

>>>.el-table th {
  line-height: 30px;
}

>>>.el-table td {
  padding: 8px 0;
}

>>>.cate_control {
  text-align: right;
}

>>>.el-table .cell {
  max-height: 50px;
}

.cell .el-button {
  margin-right: 15px;
  margin-left: 0;
}

.cell .el-button:last-child {
  margin-right: 0;
}

.cell .el-button.delete {
  color: #f66;
}

.page-settings .pdt-thumb {
  height: 50px;
  padding: 2px;
  border: 1px solid #ddd;
}

>>>.page-settings .pdt-thumb img {
  width: auto;
  height: 100%;
}

.readinput,
.sel-btn {
  margin-top: 10px;
}

.sel-btn {
  margin-left: 10px;
}

.pdtform>>>.input-size {
  width: 40em;
}

.dataform>>>.avatar-uploader {
  line-height: 0;
}

.dataform>>>.avatar-uploader-icon {
  font-size: 40px;
  color: #8c939d;
  width: 120px;
  height: 120px;
  line-height: 120px;
  text-align: center;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
}

.dataform>>>.avatar {
  width: 100%;
  max-width: 400px;
  display: block;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
}

.pane-content {
  display: flex;
  justify-content: flex-start;
  align-items: self-start;
}

.pane-content .prevview {
  zoom: 0.5;
  width: 750px;
  height: auto;
  min-height: 120px;
  border: 1px solid #ebeef5;
  margin: 60px;
  border-radius: 4px;
  /*box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);*/
  position: relative;
  overflow: hidden;
  line-height: 0;
}

.pane-content .prevview .bgimage {
  width: 100%;
  height: auto;
  position: relative;
  top: 0;
  left: 0;
  z-index: 1;
}

.pane-content .prevview .items {
  padding: 24px;
  min-width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  background-repeat: no-repeat;
  background-position: 50% 0;
  border-radius: 4px;
}

.pane-content .prevview .items .item {
  border: 1px solid #ebeef5;
  box-sizing: border-box;
  font-size: 70px;
  justify-content: center;
  align-items: center;
  display: inline-flex;
  text-shadow: 0 0 7px #fff;
  color: #000;
  cursor: pointer;
  background-position: 50% 50%;
  background-size: cover;
}

.pane-content .prevview .items.items_1 .item {
  width: 100%;
}

.pane-content .prevview .items.items_2 .item {
  width: 50%;
}

.pane-content .prevview .items.items_3 .item {
  width: 33.33%;
}

.pane-content .prevview .items.items_4 .item {
  width: 25%;
}

.pane-content .prevview .items.items_5 .item {
  width: 20%;
}

.pane-content .prevview .items.items_6 .item {
  width: 16.66%;
}

.pane-content .settings {
  width: 0;
  flex-grow: 1;
  padding: 30px 0;
}

.pane-content::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.pane-content::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 6px;
}

.ad-thumb {
  box-sizing: border-box;
  height: 40px;
  width: 40px;
  padding: 2px;
  border: 1px solid #ddd;
}

>>>.ad-thumb.showcase,
>>>.ad-thumb.showcase>img {
  width: auto !important;
}

.goods-menu {
  display: flex;
  width: 100%;
}

.goods-menu .btn {
  display: inline-flex;
  padding: 20px 10px;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.goods-menu .btn .icon {
  box-sizing: border-box;
  border: 1px solid #ddd;
  border-radius: 20px;
  width: 70px;
  height: 70px;
}

.goods-menu .btn .main-title {
  line-height: 46px;
  font-size: 26px;
  color: #333;
}

.goods-menu .btn .sub-title {
  line-height: 34px;
  font-size: 22px;
  color: #777;
}

.goods-menu .btn.active::before {
  content: "";
  display: block;
  width: 20%;
  border-bottom: 4px solid #ffb853;
  position: absolute;
  left: 40%;
  bottom: 0;
}

.goods-menu .btn.active .icon {
  border: 4px solid #ffb853;
}

.goods-menu .btn.active .main-title {
  font-size: 30px;
  font-weight: bold;
}

.goodslist .list {
  width: 100%;
  display: flex;
}

.goodslist .list .pdtlist {
  list-style: none;
  margin: 0;
  padding: 0;
}

.goodslist .list .pdtlist .pdt-item {
  display: flex;
  width: 100%;
  box-sizing: border-box;
}

.goodslist .list .pdtlist .pdt-item .info {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  font-size: 24px;
}

.goodslist .list .pdtlist .pdt-item .info .pdt-price {
  margin-bottom: 0 !important;
}

.goodslist .list.list-01 .pdtlist {
  width: 100%;
  padding: 24px 0;
}

.goodslist .list.list-01 .pdtlist .pdt-item {
  padding: 20px;
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.goodslist .list.list-01 .pdtlist .pdt-item .thumb {
  height: 200px;
  margin-right: 20px;
}

.goodslist .list.list-01 .pdtlist .pdt-item .info {
  width: 0;
}

.goodslist .list.list-01 .pdtlist .pdt-item .info span {
  line-height: 36px;
  margin-bottom: 10px;
}

.goodslist .list.list-01 .pdtlist .pdt-item .info .pdt-name {
  line-height: 40px;
  overflow: hidden;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.goodslist .list.list-01 .pdtlist .pdt-item .info .hotrent,
.goodslist .list.list-01 .pdtlist .pdt-item .info .pdt-price {
  color: #eb4b4b;
}

.goodslist .list.list-01 .pdtlist .pdt-item .info .pdt-price {
  font-size: 28px;
}

.goodslist .list.list-01 .pdtlist .pdt-item .info .pdt-price::before,
.goodslist .list.list-01 .pdtlist .pdt-item .info .pdt-price::after {
  font-size: 22px;
}

.goodslist .list.list-01 .pdtlist .pdt-item .info .pdt-price::before {
  content: "￥";
}

.goodslist .list.list-01 .pdtlist .pdt-item .info .pdt-price::after {
  content: "/天";
}

.color-block {
  display: block;
  width: 30px;
  height: 30px;
}
</style>